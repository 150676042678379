import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import z from "zod";
import ObjectAssert from "../../ObjectAssert";
import { entityIdMap } from "../../const/entity-id-map";
import { ExcludeMethod } from "../../lib";
import Entity from "../Entity";
dayjs.extend(isSameOrAfter);

export const smtpAuth = ['plain', 'login', 'cram-md5'] as const;
export type SmtpAuth = typeof smtpAuth[number];

export function smtpAuthValidator(val: string): val is SmtpAuth {
	return (smtpAuth as readonly string[]).includes(val);
}

export const smtpEncrypt = ['none', 'starttls', 'ssltls'] as const;
export type SmtpEncrypt = typeof smtpEncrypt[number];

export function smtpEncryptValidator(val: string): val is SmtpEncrypt {
	return (smtpEncrypt as readonly string[]).includes(val);
}

export const schemaRateLimiterOptions = z.object({
	max: z.number(),
	recoveryTime: z.number(),
	interval: z.number(),
});
type RateLimiterOptions = z.infer<typeof schemaRateLimiterOptions>;
const RateLimiterOptionsValidator = (val: unknown): val is RateLimiterOptions => schemaRateLimiterOptions.safeParse(val).success;

export class MailShopEntity extends Entity {
	static typeId = entityIdMap['mail.shop_entity'];

	mail?: string;
	mailSenderName?: string;
	smtpHost?: string;
	smtpPort?: number;
	smtpAuth?: SmtpAuth;
	smtpEncrypt?: SmtpEncrypt;
	smtpUser?: string;
	smtpPass?: string;
	mailCc?: string[];
	mailBcc?: string[];
	isMailPending?: boolean;
	rateLimit?: RateLimiterOptions;

	constructor(obj: unknown) {
		super(obj);

		const assert = new ObjectAssert(obj);
		assert.assign(this, {
			mail: { isMandatory: false, type: 'string' },
			mailSenderName: { isMandatory: false, type: 'string' },
			smtpHost: { isMandatory: false, type: 'string' },
			smtpPort: { isMandatory: false, type: 'number' },
			smtpAuth: { isMandatory: false, type: 'string', validator: smtpAuthValidator },
			smtpEncrypt: { isMandatory: false, type: 'string', validator: smtpEncryptValidator },
			smtpUser: { isMandatory: false, type: 'string' },
			smtpPass: { isMandatory: false, type: 'string' },
			mailCc: { isMandatory: false, isArray: true, type: 'string' },
			mailBcc: { isMandatory: false, isArray: true, type: 'string' },
			isMailPending: { isMandatory: false, type: 'boolean' },
			rateLimit: { isMandatory: false, type: 'object', validator: RateLimiterOptionsValidator },
		});
	}

	toJSON(): Record<string, unknown> {
		return Object.assign(super.toJSON(), {
			mail: this.mail,
			mailSenderName: this.mailSenderName,
			smtpHost: this.smtpHost,
			smtpPort: this.smtpPort,
			smtpAuth: this.smtpAuth,
			smtpEncrypt: this.smtpEncrypt,
			smtpUser: this.smtpUser,
			smtpPass: this.smtpPass,
			mailCc: this.mailCc,
			mailBcc: this.mailBcc,
			isMailPending: this.isMailPending,
			rateLimit: this.rateLimit,
		});
	}

	isSendableMail(): boolean {
		return Boolean(
			this.mail &&
			this.smtpHost &&
			this.smtpPort &&
			this.smtpAuth &&
			this.smtpEncrypt &&
			this.smtpUser &&
			this.smtpPass
		);
	}
}
export type MailShopEntityAttr = Omit<ExcludeMethod<MailShopEntity>, 'id' | 'typeId'>;
