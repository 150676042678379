import dayjs from "dayjs";
import { UUID } from "../..";
import ObjectAssert from "../../ObjectAssert";
import { entityIdMap } from "../../const/entity-id-map";
import isUUID from "../../isUUID";
import { ExcludeMethod } from "../../lib";
import Entity from "../Entity";

export const paymentStatus = ['unclaimed', 'waiting', 'confirmed', 'no_charge'] as const;
export type PaymentStatus = typeof paymentStatus[number];

export const INIT_PAYMENT_STATUS = 'unclaimed';
export const NO_CHARGE_PAYMENT_STATUS = 'no_charge';
export const CONFIRMED_PAYMENT_STATUS = 'confirmed';

function paymentStatusValidator(val: string): val is PaymentStatus {
	return (paymentStatus as readonly string[]).includes(val);
}

const paymentPeriod = ['monthly', 'yearly'] as const;
type PaymentPeriod = typeof paymentPeriod[number];

export const YEARLY_PAYMENT_PERIOD = 'yearly';

function paymentPeriodValidator(val: string): val is PaymentPeriod {
	return (paymentPeriod as readonly string[]).includes(val);
}

export const paymentMethod = ['bankTransfer', 'collectService', 'rmsss'] as const;
type PaymentMethod = typeof paymentMethod[number];
type PaymentMethodNames = Record<PaymentMethod, string>;
export const paymentMethodNames: PaymentMethodNames = {
	bankTransfer: '銀行振込',
	collectService: 'コレクト',
	rmsss: 'BillPay[RMSSS]',
};

function paymentMethodValidator(val: string): val is PaymentMethod {
	return (paymentMethod as readonly string[]).includes(val);
}

export class Payment extends Entity {
	static typeId = entityIdMap['trade.payment'];

	clientId!: UUID;
	service!: string;
	sourceId!: string;
	date!: number;
	until?: number;
	plan?: string;
	period?: 'monthly' | 'yearly';
	amount!: number;
	status!: PaymentStatus;
	/** 請求方法 */
	method!: PaymentMethod;
	/** 請求額確定フラグ */
	amountConfirmed?: boolean;

	constructor(obj: unknown) {
		super(obj);

		const assert = new ObjectAssert(obj);
		assert.assign(this, {
			clientId: { isMandatory: true, validator: isUUID },
			service: { isMandatory: true, type: 'string' },
			sourceId: { isMandatory: true, type: 'string' },
			date: { isMandatory: true, type: 'number' },
			until: { isMandatory: false, type: 'number' },
			plan: { isMandatory: false, type: 'string' },
			period: { isMandatory: false, type: 'string', validator: paymentPeriodValidator },
			amount: { isMandatory: true, type: 'number' },
			status: { isMandatory: true, type: 'string', validator: paymentStatusValidator },
			method: { isMandatory: true, type: 'string', validator: paymentMethodValidator },
			amountConfirmed: { isMandatory: false, type: 'boolean' },
		});
	}

	toJSON(): Record<string, unknown> {
		return Object.assign(super.toJSON(), {
			clientId: this.clientId,
			service: this.service,
			sourceId: this.sourceId,
			date: this.date,
			until: this.until,
			plan: this.plan,
			period: this.period,
			amount: this.amount,
			status: this.status,
			method: this.method,
			amountConfirmed: this.amountConfirmed,
		});
	}

	getPeriodIndex(): number {
		if (!this.period || this.period === 'monthly') {
			return 0;
		}
		const start = dayjs(this.until).subtract(11, 'month').startOf('month');
		return dayjs(this.date).diff(start, 'month');
	}
}
export type PaymentAttr = Omit<ExcludeMethod<Payment>, 'typeId'>;
