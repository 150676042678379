import { Campaign, Notices, ReviewType, TopPageSummary } from '@sasagase/types';
import * as React from 'react';
import { getCampaigns, getNotices, getReviewsByTerm, getTargetTopSummary, getWarnings } from '../../../api';
import { useAPI, useAppState } from '../../../context';
import NoticeListItem from './NoticeListItem';
import ReviewSummary from './ReviewSummary';
import WarningListItem from './WarningListItem';

const NOTICE_DISP_NUM = 3;

interface TopProps {}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function Top(props: TopProps): React.ReactElement {
	const callAPI = useAPI();
	const [state] = useAppState();
	const [warnings, setWarnings] = React.useState<Notices.Content[]|null>(null);
	const [notices, setNotices] = React.useState<Notices.Content[]|null>(null);
	const [campaigns, setCampaigns] = React.useState<Campaign[]|null>(null);
	const [campaignNames, setCampaignNames] = React.useState<Map<string, string>>(new Map());
	const [reviews, setReviews] = React.useState<Record<string, any>[]|null>(null);
	const [topSummary, setTopSummary] = React.useState<TopPageSummary|null>(null);

	React.useEffect(() => {
		setWarnings(null);
		setNotices(null);
		setCampaigns(null);
		setCampaignNames(new Map());
		setReviews(null);
		setTopSummary(null);
	}, [state.params.shopId]);

	React.useEffect(() => {
		if (notices) {
			return;
		}
		return callAPI(getNotices(state.params.shopId), (err, res) => {
			if (err) {
				return;
			}
			const ns: Notices.Content[] = res.data.map((obj: Record<string, unknown>) => Notices.Content.create(obj));
			setNotices(ns.slice(0, NOTICE_DISP_NUM));
		});
	}, [notices, state.params.shopId]);

	React.useEffect(() => {
		if (warnings) {
			return;
		}
		return callAPI(getWarnings(state.params.shopId), (err, res) => {
			if (err) {
				return;
			}
			const ns: Notices.Content[] = res.data.map((obj: Record<string, unknown>) => Notices.Content.create(obj));
			setWarnings(ns);
		});
	}, [warnings, state.params.shopId]);

	React.useEffect(() => {
		if (campaigns) {
			return;
		}
		return callAPI(getCampaigns(state.params.shopId), (err, res) => {
			if (err) {
				return;
			}
			const camps: Campaign[] = [];
			const campNames = new Map();
			for (const obj of res.data) {
				const camp = Campaign.create(obj);
				camps.push(camp);
				campNames.set(camp.id, camp.name);
			}
			setCampaigns(camps.filter(camp => camp.isAlive()));
			setCampaignNames(campNames);
		});
	}, [campaigns, state.params.shopId]);

	React.useEffect(() => {
		if (!campaignNames) {
			return;
		}

		const now = new Date();
		const lastMonth = new Date (now.getFullYear(), now.getMonth() - 1, 1);

		return callAPI(getReviewsByTerm(state.params.shopId, lastMonth.getTime()), (err, res) => {
			if (err) {
				return;
			}
			const reviews = res.data.map((obj: Record<string, any>) => {
				return {
					id: obj.id,
					campaign_id: obj.campaign_id,
					campaign_name: campaignNames.get(obj.campaign_id),
					...obj.attributes,
				};
			});
			setReviews(reviews);
		});
	}, [campaignNames, state.params.shopId]);

	React.useEffect(() => {
		if (!campaigns) {
			return;
		}
		return callAPI(getTargetTopSummary(state.params.shopId), (err, result) => {
			if (err) {
				throw err;
			}
			setTopSummary(result.data);
		});
	}, [campaigns, state.params.shopId]);

	const getReviewNum = (campaignId: string, type: ReviewType) => {
		if (topSummary) {
			const typeKey = type === 'item' ? 'itemReviewed' : 'shopReviewed';
			return topSummary.perCampaign[campaignId]?.[typeKey] ?? 0
		}
		return '-';
	};

	const basePath = state.params?.basePath;

	return (
		<div className="ly_main__top">
			{(warnings && warnings.length > 0) &&
				<div className="bl_row">
					<div className="bl_col bl_col__12">
						<div className="bl_panel bl_panel__warning">
							<h2 className="el_lv2Heading">設定内容をご確認ください</h2>
							<ul className="bl_warningList">
								{warnings.map((content) => <WarningListItem key={content.id} basePath={basePath} content={content} />)}
							</ul>
						</div>
					</div>
				</div>
			}
			<div className="bl_row">
				<div className="bl_col bl_col__12">
					<div className="bl_panel">
						<h2 className="el_panel_ttlTop">お知らせ</h2>
						{notices &&
							<ul className="bl_noticeList">
								{notices.map(notice => <NoticeListItem key={notice.id} notice={notice} />)}
							</ul>
						}
						{/* <a className="el_noticeLink" href="#">過去のお知らせ</a> */}
					</div>
				</div>
			</div>
			<div className="bl_row">
				<div className="bl_col bl_col__6">
					<div className="bl_panel">
						<h2 className="el_panel_ttlTop el_panel_ttlTop__simple">実施中のキャンペーン累計レビュー獲得件数</h2>
						<table className="bl_table bl_table__totalReview">
							<thead className="bl_table_head">
								<tr>
									<th>キャンペーン名</th>
									<th>商品</th>
									<th>ショップ</th>
								</tr>
							</thead>
							{campaigns && 
								<tbody className="bl_table_body">
									{campaigns.map((campaign) => 
										<tr key={campaign.id}>
											<td>{campaign.name}</td>
											<td>{getReviewNum(campaign.id, 'item')}<span className="el_unit">件</span></td>
											<td>{getReviewNum(campaign.id, 'shop')}<span className="el_unit">件</span></td>
										</tr>
									)}
								</tbody>
							}
						</table>
					</div>
				</div>
				<div className="bl_col bl_col__6">
					<ReviewSummary reviews={reviews} topSummary={topSummary} />
				</div>
			</div>
		</div>
	);
}
export default Top;