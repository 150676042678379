import { TopPageSummary } from '@sasagase/types';
import dayjs from 'dayjs';
import * as React from 'react';
import { ReviewBar, ReviewDoughnut } from '../Chart';

interface SummaryData {
	yesterday: number;
	thisMonth: number;
	numberOfReviews: {
		yesterday: number,
		thisMonth: number,
	};
	reviewForm: {
		completed: number,
		waiting: number,
	};
	reviewPostingRate: {
		m2mBasis?: number,
		chartBar: {
			labels: string[],
			data: number[],
			legend: {
				prev: string,
				now: string,
			}
		},
	};
	campaignAcquistionRate: {
		top3Labels: string[],
		chartDoughnut: {
			labels: string[],
			data: number[],
		},
	};
}

function checkDays(check: Date, date: Date) {
	const start = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0);
	const end = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59);
	return checkDate(check, start, end);
}

function checkMonthly(date: Date, now: Date) {
	const start = new Date(now.getFullYear(), now.getMonth(), 1, 0, 0, 0);
	const end = new Date(now.getFullYear(), now.getMonth() + 1, 0, 23, 59, 59);
	return checkDate(date, start, end);
}

function checkDate(check: Date, start: Date, end: Date): boolean {
	return (start <= check && check <= end);
}

function toSummary(
		reviews: Record<string, any>[] | null,
		topSummary: TopPageSummary | null
	): SummaryData {
	const now = new Date();
	const yesterday = new Date();
	yesterday.setDate(yesterday.getDate() -1);
	const lastMonth = new Date(now.getFullYear(), now.getMonth() -1, 1);

	const lastMonthReviews = reviews?.filter(review => checkMonthly(new Date(review.date), lastMonth)) || [];
	const lastMonthReviewNum = [...new Set(lastMonthReviews.map(review => review.id))].length
	const thisMonthReviews = reviews?.filter(review => checkMonthly(new Date(review.date), now)) || [];
	const thisMonthReviewNum = [...new Set(thisMonthReviews.map(review => review.id))].length
	const yesterdayReviews = reviews?.filter(review => checkDays(new Date(review.date), yesterday)) || [];
	const yesterdayReviewNum = [...new Set(yesterdayReviews.map(review => review.id))].length;

	const lastMonthOrderNum = topSummary?.lastMonthOrderNum ?? 0;
	const thisMonthOrderNum = topSummary?.thisMonthOrderNum ?? 0;

	// レビュー投稿率
	const lastMonthPostingRate = (lastMonthReviewNum > 0 && lastMonthOrderNum > 0)
									? Math.round(lastMonthReviewNum * 100 / lastMonthOrderNum) : 0;
	const thisMonthPostingRate = (thisMonthReviewNum > 0 && thisMonthOrderNum > 0)
									? Math.round(thisMonthReviewNum * 100 / thisMonthOrderNum) : 0;
	const m2mBasisPostingRate = (lastMonthPostingRate > 0)
									? Math.round((thisMonthPostingRate - lastMonthPostingRate) * 100 / lastMonthPostingRate) : undefined;

	// キャンペーン獲得比率(キャンペーンごとの受注に対するレビュー数での比率) 
	const car: Record<string, number> = {};
	const campaignNames: Map<string, string> = new Map();
	for (const review of thisMonthReviews) {
		if (!car[review.campaign_id]) {
			car[review.campaign_id] = 0;
			campaignNames.set(review.campaign_id, review.campaign_name);
		}
		car[review.campaign_id] += 1;
	}
	let sum = 0;
	const carArray = Object.keys(car).map(campaignId => {
		const orderNum = topSummary?.perCampaign[campaignId]?.orderNum ?? 0;
		const val = Math.round(car[campaignId] * 100 / orderNum);
		sum += val;
		return {
			campaignId: campaignId,
			campaignName: campaignNames.get(campaignId) || '',
			number: val,
			percentage: 0,
		}
	});
	carArray.map(val => {
		val.percentage = Math.round(val.number * 100 / sum);
		return val;
	});

	const top3Reviews = carArray.sort((a,b) => {
		return b.number - a.number;
	}).slice(0, 3);
	const doughnutData = [ ...top3Reviews ];
	if (carArray.length > 3) {
		doughnutData.push({
			campaignId: '',
			campaignName: 'その他',
			number: 0,
			percentage: 100 - top3Reviews.reduce((sum, rev) => sum + rev.percentage, 0),
		});
	}

	return {
		yesterday: yesterday.getTime(),
		thisMonth: now.getMonth() + 1,
		numberOfReviews: {
			yesterday: yesterdayReviewNum,
			thisMonth: thisMonthReviewNum,
		},
		reviewForm: {
			completed: topSummary?.perApplyStatus.done || 0,
			waiting: topSummary?.perApplyStatus.waitingAppling || 0,
		},
		reviewPostingRate: {
			m2mBasis: m2mBasisPostingRate,
			chartBar: {
				labels: [(lastMonth.getMonth() + 1) + "月", (now.getMonth() + 1) + "月"],
				data: [lastMonthPostingRate, thisMonthPostingRate],
				legend: {
					prev: lastMonthPostingRate + "%",
					now: thisMonthPostingRate + "%",
				}
			},
		},
		campaignAcquistionRate: {
			top3Labels: top3Reviews.map(rev => rev.campaignName),
			chartDoughnut: {
				labels: doughnutData.map(rev => rev.campaignName),
				data: doughnutData.map(rev => rev.percentage),
			},
		}
	};
}

interface ReviewSummaryProps {
	reviews: Record<string, any>[] | null;
	topSummary: TopPageSummary | null;
}

export function ReviewSummary(props: ReviewSummaryProps): React.ReactElement | null {
	const summary = React.useMemo(() => {
		return toSummary(props.reviews, props.topSummary);
	}, [props.reviews, props.topSummary]);

	const toDateString = (milliepoc: number | undefined): string => {
		if (!milliepoc) {
			return '';
		}
		return dayjs(milliepoc).format('M月DD日');
	};
	const toPlusMinusString = (value: number | string): string => {
		if (typeof value == 'string') {
			return value;
		} else if (value >= 0) {
			return "+" + parseInt(value.toString());
		} else {
			return value.toString();
		}
	};

	if (!summary) {
		return null;
	}

	const plusMinusClass = summary.reviewPostingRate.m2mBasis ?
							summary.reviewPostingRate.m2mBasis >= 0 ? 'is_plus' : 'is_minus' :
							'';

	return (
		<>
			<div className="bl_row">
				<div className="bl_col bl_col__6">
					<div className="bl_panel">
						<h2 className="el_panel_ttlTop hp_justified">昨日のレビュー獲得件数<span>{toDateString(summary.yesterday)}</span></h2>
						<p className="el_panel_totalNum">{summary.numberOfReviews.yesterday}<span className="el_panel_totalNum_unit">件</span></p>
					</div>
				</div>
				<div className="bl_col bl_col__6">
					<div className="bl_panel">
						<h2 className="el_panel_ttlTop">{summary.thisMonth}月間累計レビュー獲得件数</h2>
						<p className="el_panel_totalNum">{summary.numberOfReviews.thisMonth}<span className="el_panel_totalNum_unit">件</span></p>
					</div>
				</div>
			</div>
			<div className="bl_row">
				<div className="bl_col bl_col__6">
					<div className="bl_panel">
						<h2 className="el_panel_ttlTop">申込内容記入済み件数</h2>
						<p className="el_panel_totalNum">{summary.reviewForm.completed}<span className="el_panel_totalNum_unit">件</span></p>
					</div>
				</div>
				<div className="bl_col bl_col__6">
					<div className="bl_panel">
						<h2 className="el_panel_ttlTop">申込内容記入待ち件数</h2>
						<p className="el_panel_totalNum">{summary.reviewForm.waiting}<span className="el_panel_totalNum_unit">件</span></p>
					</div>
				</div>
			</div>
			<div className="bl_row">
				<div className="bl_col bl_col__6">
					<div className="bl_panel">
						<h2 className="el_panel_ttlTop">{summary.thisMonth}月レビュー投稿率(前月比)</h2>
						<ReviewBar {...summary.reviewPostingRate.chartBar} />
						{/* <!-- NOTE: プラスの場合上向き▲、マイナスは逆 is_plus, is_minus --> */}
						<p className={`el_panel_totalNum hp_justified hp_alignBaseline ${plusMinusClass}`}>
							<span className="fs_18 txt_blue">前月比</span>{toPlusMinusString(summary.reviewPostingRate.m2mBasis ?? '-')}%
						</p>
					</div>
				</div>
				<div className="bl_col bl_col__6">
					<div className="bl_panel">
						<h2 className="el_panel_ttlTop">{summary.thisMonth}月キャンペーン獲得比率</h2>
						<ReviewDoughnut {...summary.campaignAcquistionRate.chartDoughnut} />
						<ul className="bl_rank">
							{summary.campaignAcquistionRate.top3Labels.map((label, index) =>
								<li key={`campaignAcquistionRate_${index}`} className={`bl_rank_item bl_rank_item__${index+1}`}>{label}</li>
							)}
						</ul>
						{summary.campaignAcquistionRate.top3Labels.length > 0 &&
							<div className="bl_panel_headerFooter">
								<p>※実施中のキャンペーン上位3位まで表示</p>
							</div>
						}
					</div>
				</div>
				
			</div>
		</>
	);
}
export default ReviewSummary;