import { Client, Payment, PAYMENT_DEFAULT_PLANS, PAYMENT_PLANS, ShopEntity } from '@sasagase/types';
import dayjs from 'dayjs';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { getClient, getPaymentPdf, getPayments, getShopEntity } from '../../../api';
import { useAPI, useAppState } from '../../../context';
import { saveFile } from '../reward/RequestList/saveCSVFile';

const defaultPlan = PAYMENT_DEFAULT_PLANS['review'];
const paymentPlans = PAYMENT_PLANS['review'];

interface ShopPlanProps {}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function ShopPlan(props: ShopPlanProps): React.ReactElement | null {
	const [state] = useAppState();
	const shopId = state.params.shopId;
	const shop = state.shop;

	const { register, getValues } = useForm({
		defaultValues: {
			'paymentId': '',
		},
	});

	const callAPI = useAPI();
	const [shopEntity, setShopEntity] = React.useState<ShopEntity|null>(null);
	const [client, setClient] = React.useState<Client|null>(null);
	const [payments, setPayments] = React.useState<Payment[]>([]);
	const [loading, setLoading] = React.useState<boolean>(false);

	React.useEffect(() => {
		if (!shopId) {
			return;
		}

		return callAPI(getShopEntity(shopId), (err, result) => {
			if (err) {
				return;
			}
			const shopEntity = new ShopEntity(result.data);
			setShopEntity(shopEntity);
		});
	}, [shopId]);

	React.useEffect(() => {
		if (!shopEntity) {
			return;
		}

		return callAPI(getClient(shopEntity.clientId, shopId), (err, result) => {
			if (err) {
				return;
			}
			const client = new Client(result.data);
			setClient(client);
		});
	}, [shopEntity]);

	React.useEffect(() => {
		if (!shopEntity) {
			return;
		}

		return callAPI(getPayments(shopEntity.clientId, shopId), (err, result) => {
			if (err) {
				return;
			}
			const payments: Payment[] = result.data.map((row: Record<string, unknown>) => new Payment(row));
			setPayments(payments.sort((a, b) => b.date - a.date));
		});
	}, [shopEntity]);

	if (!shop || !shopEntity || !client) {
		return null;
	}

	const useServiceSquare = shop.useServiceSquare;
	const plan = shopEntity.plan ?? defaultPlan;
	const planInfo = paymentPlans[plan] ?? {};

	const showZip = (zip = '') => {
		if (zip.length === 7) {
			return `${zip.substring(0, 3)}-${zip.substring(3)}`;
		}
		return zip;
	};

	const handleClickDownloadPDF = async () => {
		const id = getValues('paymentId');
		const payment = payments.find((payment) => payment.id === id);
		if (!payment) {
			return;
		}

		setLoading(true);
		const result = await callAPI(getPaymentPdf(shopEntity.clientId, shopId, id));
		const blob = new Blob([result.data], { type: `application/pdf` });
		saveFile(`請求書_${dayjs(payment.date).format('YYYY年M月')}システム利用料.pdf`, blob);
		setLoading(false);
	};

	return (
		<>
			<h1 className="el_pageTtl">{useServiceSquare ? 'プラン' : 'プラン・請求情報'}</h1>
			<div className="bl_row">
				<div className="bl_col bl_col__8">
					<div className="bl_panel bl_panel__bt">
						<div className="bl_panel_row">
							<h2 className="el_lv2Heading">プラン</h2>
							<table className="bl_table bl_table__plan">
								<tbody className="bl_table_body">
									<tr>
										<td>現在のプラン</td>
										<td>{planInfo.name}</td>
									</tr>
									<tr>
										<td>料金</td>
										<td>
											{planInfo.period === 'monthly' ? '月額 ' : '年額 '}
											{planInfo.amount.toLocaleString()} 円
										</td>
									</tr>
								</tbody>
							</table>
						</div>
						{!useServiceSquare &&
							<div className="bl_panel_row">
								<h2 className="el_lv2Heading">請求</h2>
								<table className="bl_table bl_table__plan">
									<tbody className="bl_table_body">
										<tr>
											<td>契約者情報</td>
											<td>
												{client.name}<br />
												〒{showZip(client.zip)} {client.address} {client.building}<br />
												{client.phone}
											</td>
										</tr>
										<tr>
											<td>請求書</td>
											<td>
												<div>
													<div className="el_selectWrap">
														<select name="paymentId" ref={register} disabled={loading}>
															<option value="" disabled hidden>ダウンロードする請求書を選択してください</option>
															{payments.map(payment => 
																<option key={payment.id} value={payment.id}>{dayjs(payment.date).format('YYYY年MM月')}システム利用料</option>
															)}
														</select>
													</div>
													<button className="el_btnBlueInv mt_16" type="button" onClick={handleClickDownloadPDF} disabled={loading}>{loading ? 'ダウンロード中' : 'ダウンロード'}</button>
												</div>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						}
					</div>
				</div>
			</div>
		</>
	);
}
export default ShopPlan;