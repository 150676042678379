export interface PlanInfo {
	/** プラン名 */
	name: string;

	/** プラン名(短縮) */
	short: string;

	/** プラン費用 */
	amount: number;

	/** 契約期間(monthly:月間, yearly:年間) */
	period: 'monthly' | 'yearly';

	/** RMSSS利用 */
	useServiceSquare: boolean;
}

// review: ラクラクあつまレビューの契約プラン
const REVIEW_DEFAULT_PLAN = 'BASIC_FIRST_M';
const REVIEW_RMSSS_DEFAULT_PLAN = 'BASIC_RMSSS_FIRST_M';
const REVIEW_PAYMENT_PLANS: Record<string, PlanInfo> = {
	BASIC_FIRST_M: {
		name: '基本プラン【月間】',
		short: '基本【月】',
		amount: 11000,
		period: 'monthly',
		useServiceSquare: false
	},
	BASIC_FIRST_Y: {
		name: '基本プラン【年間】',
		short: '基本【年】',
		amount: 11000 * 11,
		period: 'yearly',
		useServiceSquare: false
	},
	BASIC_MULTI_M: {
		name: '割引プラン【月間】',
		short: '割引【月】',
		amount: 5500,
		period: 'monthly',
		useServiceSquare: false
	},
	BASIC_MULTI_Y: {
		name: '割引プラン【年間】',
		short: '割引【年】',
		amount: 5500 * 11,
		period: 'yearly',
		useServiceSquare: false
	},
	BASIC_RMSSS_FIRST_M: {
		name: '基本プラン【RMSサービススクエア・月間】',
		short: '基本【RMSSS・月】',
		amount: 11000,
		period: 'monthly',
		useServiceSquare: true
	},
	BASIC_RMSSS_MULTI_M: {
		name: '割引プラン【RMSサービススクエア・月間】',
		short: '割引【RMSSS・月】',
		amount: 5500,
		period: 'monthly',
		useServiceSquare: true
	},
	BASIC_FREE_M: {	// ※社内店舗設定用
		name: '無料プラン【月間】',
		short: '無料【月】',
		amount: 0,
		period: 'monthly',
		useServiceSquare: false
	},
};

export const PAYMENT_DEFAULT_PLANS: Record<string, string> = {
	review: REVIEW_DEFAULT_PLAN,
	yReview: REVIEW_DEFAULT_PLAN,
	rmsss: REVIEW_RMSSS_DEFAULT_PLAN,
};

export const PAYMENT_PLANS: Record<string, Record<string, PlanInfo>> = {
	review: REVIEW_PAYMENT_PLANS,
	yReview: REVIEW_PAYMENT_PLANS,
};