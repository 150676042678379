import { CSVFile } from "@sasagase/types";
import dayjs from "dayjs";
import iconv from 'iconv-lite';
import JSZip from 'jszip';

/**
 * CSVファイルを一つのZIPファイルにまとめる
 * @param csvFiles CSVファイルリスト
 */
export async function saveZIPFiles(csvFiles: CSVFile[], date: Date): Promise<void> {
	const zip = new JSZip();
	const folderName = `${dayjs(date).format('YYYYMMDDHHmmss')}ラクラクあつまレビュー`;
	if (csvFiles.length === 1) {
		// 一つのファイルを圧縮した場合、解凍するとそのファイルがそのまま配置されるため、複数ファイルと同じ階層となるようにフォルダへ格納して圧縮する
		const csv = csvFiles[0];
		const folder = zip.folder(folderName);
		const buff = iconv.encode(csv.content, csv.charset, { addBOM: csv.bom });
		const blob = new Blob([buff], { type: `text/csv; charset=${csv.charset}` });
		folder?.file(csv.filename, blob);
	} else {
		for (const csv of csvFiles) {
			const buff = iconv.encode(csv.content, csv.charset, { addBOM: csv.bom });
			const blob = new Blob([buff], { type: `text/csv; charset=${csv.charset}` });
			zip.file(csv.filename, blob);
		}
	}
	const zipBlob = await zip.generateAsync({ type: 'blob' });
	saveFile(`${folderName}.zip`, zipBlob);
}

export async function saveCSVFile(csv: CSVFile): Promise<void> {
	const buff = iconv.encode(csv.content, csv.charset, { addBOM: csv.bom });
	const blob = new Blob([buff], { type: `text/csv; charset=${csv.charset}`});

	saveFile(csv.filename, blob);
}

export function saveFile(filename: string, blob: Blob): void {
	const url = URL.createObjectURL(blob);
	
	const anchor = document.createElement('a');
	anchor.href = url;
	anchor.download = filename;
	anchor.click();

	setTimeout(() => URL.revokeObjectURL(url), 100);
}