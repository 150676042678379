import * as React from 'react';
import { hot } from 'react-hot-loader';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useAppState } from '../context';
import ContextProvider from './ContextProvider';
import Review from './Review';
import { ArticleCategoryList } from './Review/Article';
import { Contact } from './Review/Contact';
import { FaqDetail, FaqList } from './Review/Faq';
import Login from './Review/Login';
import Management from './Review/Management';
import { AccountActivate, AccountCompleted, AccountPasswordReset, AccountRecover, AccountRegist, Notfound } from './Review/NotLoggedin';
import { ShopPlan } from './Review/ShopPlan';
import Top from './Review/Top';
import TwoFactorAuthEdit from './Review/TwoFactorAuthEdit';
import { AccountSettingEdit, AccountSettingList, RakutenSetting, ShopSetting, SystemAlert } from './Review/account';
import { Acquisition, ByCase, ByTime, PostRate, ReceptionRate } from './Review/analysis';
import Guide from './Review/guide/Guide';
import { Exclusion, NotCoveredEdit, NotCoveredList, Setting, TemplateEdit, TemplateList } from './Review/mail';
import { CampaignEdit, CampaignList, GroupEdit, GroupList, RequestList, RewardEdit, RewardList, Target } from './Review/reward';
import When from './When';

const DefaultRedirect: React.FunctionComponent = () => <Navigate to=".." replace />;
interface DefaultShopRedirectProps {}
const DefaultShopRedirect: (props: DefaultShopRedirectProps) => React.ReactElement = () => {
	const [state] = useAppState();
	const firstShopId = state.user?.getEnableCaps('review_login')[0]?.entityId;

	if (!firstShopId) {
		return <div>無効なIDかログイン権限がありません。</div>;
	}
	return <Navigate to={firstShopId} replace />;
};

const App: React.FunctionComponent = () => {
	return (
		<ContextProvider>
			<When not loggedin>
				<Routes>
					<Route path="/account/recover" element={<AccountRecover />} />
					<Route path="/account/reset/:token" element={<AccountPasswordReset />} />
					<Route path="/account/activate/:token" element={<AccountActivate />} />
					<Route path="/account/regist/:token" element={<AccountRegist />} />
					<Route path="/account/completed/:type" element={<AccountCompleted />} />
					<Route path="/notfound" element={<Notfound />} />
					<Route path="*" element={<Login />} />
				</Routes>
			</When>
			<When loggedin>
				<Routes>
					<Route path="/" element={<Navigate to="/review" replace />} />
					<Route path="/review" element={<DefaultShopRedirect />} />
					<Route path="/review/:shopId" element={<Review />}>
						<Route index element={<Top />} />
						<Route path="faq" element={<FaqList />} />
						<Route path="faq/:faqId" element={<FaqDetail />} />
						<Route path="article/category/:termId" element={<ArticleCategoryList />} />
						<Route path="guide/*" element={<Guide />} />
						<Route path="contact" element={<Contact />} />
						<Route path="management" element={<Management />} />
						<Route path="two-factor-auth-setting" element={<TwoFactorAuthEdit />} />
						<Route path="reward/target" element={<Target />} />
						<Route path="reward/request" element={<RequestList />} />
						<Route path="reward/reward" element={<RewardList />} />
						<Route path="reward/reward/:rewardId/:flag" element={<RewardEdit />} />
						<Route path="reward/reward/:rewardId" element={<RewardEdit />} />
						<Route path="reward/group" element={<GroupList />} />
						<Route path="reward/group/:groupId/:flag" element={<GroupEdit />} />
						<Route path="reward/group/:groupId" element={<GroupEdit />} />
						<Route path="reward/campaign" element={<CampaignList />} />
						<Route path="reward/campaign/:campaignId/:flag" element={<CampaignEdit />} />
						<Route path="reward/campaign/:campaignId" element={<CampaignEdit />} />
						<Route path="mail/setting" element={<Setting />} />
						<Route path="mail/template" element={<TemplateList />} />
						<Route path="mail/template/:templateId/:flag" element={<TemplateEdit />} />
						<Route path="mail/template/:templateId" element={<TemplateEdit />} />
						<Route path="mail/not-covered" element={<NotCoveredList />} />
						<Route path="mail/not-covered/:notCoveredId/:flag" element={<NotCoveredEdit />} />
						<Route path="mail/not-covered/:notCoveredId" element={<NotCoveredEdit />} />
						<Route path="mail/exclusion" element={<Exclusion />} />
						<Route path="analysis/acquisition" element={<Acquisition />} />
						<Route path="analysis/reception-rate" element={<ReceptionRate />} />
						<Route path="analysis/post-rate" element={<PostRate />} />
						<Route path="analysis/by-time" element={<ByTime />} />
						<Route path="analysis/by-case" element={<ByCase />} />
						<Route path="account/account-setting" element={<AccountSettingList />} />
						<Route path="account/account-setting/:userId" element={<AccountSettingEdit />} />
						<Route path="account/rakuten-setting" element={<RakutenSetting />} />
						<Route path="account/shop-setting" element={<ShopSetting />} />
						<Route path="account/system-alert" element={<SystemAlert />} />
						<Route path="shop/plan" element={<ShopPlan />} />
						<Route path="*" element={<DefaultRedirect />} />
					</Route>
					<Route path="*" element={<Navigate to="/" replace />} />
				</Routes>
			</When>
		</ContextProvider>
	);
};
export default hot(module)(App);
