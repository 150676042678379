import { APIRequest } from '.';
import { uri } from '../lib';

export function getClient(clientId: string, shopId: string): APIRequest {
	return {
		method: 'get',
		url: uri`/api/trade/${clientId}/client/${shopId}`,
	};
}
export function getPayments(clientId: string, shopId: string): APIRequest {
	return {
		method: 'get',
		url: uri`/api/trade/${clientId}/payment/${shopId}`,
	};
}
export function getPaymentPdf(clientId: string, shopId: string, paymentId: string): APIRequest {
	const params = new URLSearchParams();
	params.append('contentType', 'pdf');
	return {
		method: 'get',
		url: uri`/api/trade/${clientId}/payment/${shopId}/${paymentId}?` + params.toString(),
		responseType: 'arraybuffer',
	};
}